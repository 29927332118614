import { put } from 'redux-saga/effects';
import { cloneDeep, find, get } from 'lodash';

export function* handleFetchListWalletNfts(action: any): any {
    // try {
    // 	const owner = action?.payload;
    // 	const req1 = fetchMyNotClaimNfts({ owner: owner?.publicKey });
    // 	const req2 = fetchMyFreeNfts({ owner: owner?.publicKey });
    // 	const req3 = boxService.getBoughtBoxes({ wallet: action?.payload });
    // 	const res = yield Promise.all([req1, req2, req3]);
    // 	const listNfts = res[2]?.data;
    // 	const tempListNfts = listNfts?.filter((item: any) => !item?.isClaim);
    // 	const claimableNfts = get(res, '[0].data.data', []);
    // 	let tempClaimableNfts = cloneDeep(tempListNfts);
    // 	if (claimableNfts?.length > 0) {
    // 		for (let i = 0; i < tempListNfts?.length; i++) {
    // 			const foundObj = find(
    // 				claimableNfts,
    // 				it => it?.petId === tempListNfts[i]?.mint?.toString()
    // 			);
    // 			if (foundObj) {
    // 				tempClaimableNfts[i] = { ...foundObj, nftId: tempListNfts[i]?.id };
    // 			}
    // 		}
    // 	}
    // 	yield put(
    // 		getWalletNftSuccess({
    // 			claimableNfts: tempClaimableNfts,
    // 			walletNfts: get(res, '[1].data.data', [])
    // 		})
    // 	);
    // } catch (error) {}
}
