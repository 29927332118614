import { Helmet } from 'react-helmet-async';
import ButtonTabs, { IButtonTab } from '../components/button-tabs';
import { useState } from 'react';
import { Button, Card, Col, Dropdown, FormControl, Row } from 'react-bootstrap';
import NewCreationsCard from '../components/new-creations-card';
// import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';

const MemePage = () => {
	const TABS: IButtonTab[] = [
		{ key: 'pump', text: 'Pump' },
		{ key: 'new-creation', text: 'New Creation' },
		{ key: 'completing', text: 'Completing' },
		{ key: 'soaring', text: 'Soaring' },
		{ key: 'completed', text: 'Completed' }
	];
	const [activeTabKey, setActiveTabkey] = useState(TABS[0].key);
	const onTabChange = (tabKey: string) => {
		setActiveTabkey(tabKey);
	};
	return (
		<div className="mm-meme">
			<Helmet>
				<title>Meme</title>
				<meta name="description" content="Your page description." />
				<meta property="og:title" content="Your Open Graph Title" />
				<meta property="og:description" content="Your Open Graph Description" />
				<meta property="og:image" content="url-to-your-image" />
				{/* Add more meta tags as needed */}
			</Helmet>
			<div className="mm-page-header">
				<div className="mm-page-header-left">
					<Button variant="text" className="btn-tab px-1 active">
						<img src="/assets/images/icons/icon-pump.svg" alt="" />
						<span className="ms-2">Pump</span>
					</Button>
					<Button variant="text" className="btn-tab px-1">
						<img src="/assets/images/icons/icon-moon.svg" alt="" />
						<span className="ms-2">Moonshot</span>
					</Button>
					<ButtonTabs
						items={TABS}
						activeKey={activeTabKey}
						onChange={onTabChange}
					/>
					<Button size="sm" variant="outline-success">
						<span className="me-1">Pump Alert</span>
						<img src="/assets/images/icons/icon-arrow-right.svg" alt="" />
					</Button>
				</div>
				<div className="mm-page-header-right">
					<img src="/assets/images/icons/icon-buy.svg" alt="buy" />
					<span>Linked Buy</span>
					<div className="input-wrap">
						<img src="/assets/images/icons/icon-solana.svg" alt="sol" />
						<FormControl type="number" size="sm" />
					</div>
					<button type="button">
						<img src="/assets/images/icons/icon-setting.svg" alt="setting" />
					</button>
				</div>
			</div>
			<Row className="gx-3">
				<Col md={4}>
					<Card className="full">
						<Card.Header>
							<Card.Title>🌱 New Creations</Card.Title>
							<Dropdown>
								<Dropdown.Toggle variant="text" size="sm" id="dropdown-basic">
									<img src="/assets/images/icons/icon-filter.svg" alt="" />
									<span>Filter</span>
								</Dropdown.Toggle>

								<Dropdown.Menu align="end">
									<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
									<Dropdown.Item href="#/action-2">
										Another action
									</Dropdown.Item>
									<Dropdown.Item href="#/action-3">
										Something else
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Card.Header>
						<Card.Body>
							<div className="mm-card-list">
								<NewCreationsCard />
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col md={4}>
					<Card className="full">
						<Card.Header>
							<Card.Title>💊 Completing</Card.Title>
							<Dropdown>
								<Dropdown.Toggle variant="text" size="sm" id="dropdown-basic">
									<img src="/assets/images/icons/icon-filter.svg" alt="" />
									<span>Filter</span>
								</Dropdown.Toggle>

								<Dropdown.Menu align="end">
									<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
									<Dropdown.Item href="#/action-2">
										Another action
									</Dropdown.Item>
									<Dropdown.Item href="#/action-3">
										Something else
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Card.Header>
						<Card.Body>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi eum
							aspernatur magnam porro adipisci, facilis quod voluptatibus
							dignissimos dolorem, vitae minima soluta iure culpa ea delectus,
							necessitatibus doloremque laboriosam. Doloribus?
						</Card.Body>
					</Card>
				</Col>
				<Col md={4}>
					<Card className="full">
						<Card.Header>
							<Card.Title>🐣 Completed</Card.Title>
							<Dropdown>
								<Dropdown.Toggle variant="text" size="sm" id="dropdown-basic">
									<img src="/assets/images/icons/icon-filter.svg" alt="" />
									<span>Filter</span>
								</Dropdown.Toggle>

								<Dropdown.Menu align="end">
									<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
									<Dropdown.Item href="#/action-2">
										Another action
									</Dropdown.Item>
									<Dropdown.Item href="#/action-3">
										Something else
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Card.Header>
						<Card.Body>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi eum
							aspernatur magnam porro adipisci, facilis quod voluptatibus
							dignissimos dolorem, vitae minima soluta iure culpa ea delectus,
							necessitatibus doloremque laboriosam. Doloribus?
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{/* <AdvancedRealTimeChart theme="dark" autosize></AdvancedRealTimeChart> */}
		</div>
	);
};
export default MemePage;
