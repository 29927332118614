import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core';

export function useActiveWeb3React() {
    const context = useWeb3ReactCore<Web3Provider>();
    return context;
}

export default function getLibrary(provider: any): Web3Provider {
    const library = new Web3Provider(provider);
    library.pollingInterval = 3000;
    return library;
}
