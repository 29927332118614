import BigNumber from 'bignumber.js';

export const BIG_TEN = new BigNumber(10);

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
    return new BigNumber(amount).times(BIG_TEN.pow(decimals));
};

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals));
};

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
    return getBalanceAmount(balance, decimals).toNumber();
};

export const getFullDisplayBalance = (balance: any, decimals = 18, displayDecimals?: number) => {
    return getBalanceAmount(balance, decimals)
        .toFixed(displayDecimals ?? 5)
        .replace(/\.?0+$/, '');
};

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
    const options = {
        minimumFractionDigits: minPrecision,
        maximumFractionDigits: maxPrecision,
    };
    return number.toLocaleString(undefined, options);
};

export const formatNumberDownRound = (number: number | null | undefined, decimal = 3, suffixes = '') => {
    if (number === null || number === undefined) return 0;
    const bigValue = new BigNumber(number);
    const bigValueFormatted = bigValue.toFormat(decimal, BigNumber.ROUND_DOWN, {
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
    });
    if (bigValueFormatted === 'NaN') {
        return `0 ${suffixes}`;
    }
    // remove zero and dot in decimal part (ex: 1,111.00 => 1,111)
    const valueConcated = bigValueFormatted.replace(/\.?0+$/, '');

    return `${valueConcated} ${suffixes}`;
};

export const formatBalance = (balance: string | number, decimals = 18, displayDecimals = 18) => {
    const balanceBig = new BigNumber(balance);
    return getBalanceAmount(balanceBig, decimals).toFixed(displayDecimals);
};
