import { Button, ButtonGroup } from 'react-bootstrap';
import './index.scss';

export interface IButtonTab {
	key: string;
	text: string;
}
export type ButtonTabsProps = {
	items: IButtonTab[];
	activeKey: string;
	onChange: (key: string) => void;
};
const ButtonTabs = ({ items, activeKey, onChange }: ButtonTabsProps) => {
	return (
		<ButtonGroup size="sm" className="mm-button-tabs">
			{items.map(item => (
				<Button
					className="mm-button-tab"
					key={item.key}
					variant={item.key === activeKey ? 'secondary' : 'outline-secondary'}
					onClick={() => onChange(item.key)}
				>
					{item.text}
				</Button>
			))}
		</ButtonGroup>
	);
};
export default ButtonTabs;
