import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import SidebarItem, { ISidebarItem } from './item';
export type SidebarProps = {
	logo: string;
	homepageUrl: string;
	menus: ISidebarItem[];
	version?: string;
	isSidebarItemActive: (data: ISidebarItem) => boolean;
	onSidebarItemClick?: (event: MouseEvent, menu: ISidebarItem) => void;
};
const Sidebar = ({
	logo,
	homepageUrl,
	menus,
	version = '1.0',
	isSidebarItemActive,
	onSidebarItemClick = () => {}
}: SidebarProps) => {
	return (
		<div className="mm-sidebar">
			<Link to={homepageUrl} className="logo">
				<img src={logo} alt="" />
			</Link>
			<ul className="menu">
				{menus.map(menu => (
					<SidebarItem
						key={menu.id}
						data={menu}
						isSidebarItemActive={isSidebarItemActive}
						onClick={e => onSidebarItemClick(e, menu)}
					/>
				))}
			</ul>
			<div className="version">v{version}</div>
		</div>
	);
};
export default Sidebar;
