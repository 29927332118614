import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import './link.scss';

export interface IFooterLink {
	text?: string;
	icon?: string;
	link?: string;
	target?: string;
	count?: string;
	countStyle?: CSSProperties;
}
export type FooterLinkProps = {
	data: IFooterLink;
	small?: boolean;
};
const FooterLink = ({ data, small }: FooterLinkProps) => {
	return (
		<Link
			to={data.link || ''}
			target={data.target}
			className={`mm-footer-link ${small ? 'sm' : ''}`}
		>
			{data.icon ? <img src={data.icon} alt={data.text} /> : <></>}
			{data.text ? <span>{data.text}</span> : <></>}
			{data.count ? (
				<span className={`count ${data.countStyle}`} style={data.countStyle}>
					{data.count}
				</span>
			) : (
				<></>
			)}
		</Link>
	);
};
export default FooterLink;
