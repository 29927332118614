import {
	ConnectionType,
	getConnection,
	tryActivateConnector,
	tryDeactivateConnector
} from '../../libs/connections';
import { NETWORK_NAME_MAPPING } from '../../libs/constants';
import Button, { ButtonVariant } from '../button/Button';

export const Option = ({
	isEnabled,
	isConnected,
	connectionType,
	onActivate,
	onDeactivate,
	onDismiss,
	buttonStyle
}: {
	isEnabled: boolean;
	isConnected: boolean;
	connectionType: ConnectionType;
	onActivate: (connectionType: ConnectionType) => void;
	onDeactivate: (connectionType: null) => void;
	onDismiss: any;
	buttonStyle?: ButtonVariant;
}) => {
	const onClick = async () => {
		onDismiss();
		if (isConnected) {
			const deactivation = await tryDeactivateConnector(
				getConnection(connectionType).connector
			);
			// undefined means the deactivation failed
			if (deactivation === undefined) {
				return;
			}
			onDeactivate(deactivation);
			return;
		}

		const activation = await tryActivateConnector(
			getConnection(connectionType).connector
		);
		if (!activation) {
			return;
		}
		onActivate(activation);
		return;
	};

	return (
		<Button
			variant={buttonStyle}
			className="w-100"
			onClick={onClick}
			disabled={!isEnabled}
		>
			{`${isConnected ? 'Disconnect' : ''} ${
				NETWORK_NAME_MAPPING[connectionType]
			}`}
		</Button>
	);
};
