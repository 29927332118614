import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Web3ContextProvider } from './components/evm-web3/Web3ContextProvider';
import ModalProvider from './components/wallet-connect-modal/ModalContext';
import { ApplicationUpdater } from './libs/updater';
import { store } from './redux/store';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<HelmetProvider>
			<Provider store={store}>
				<Web3ContextProvider>
					<ModalProvider>
						<ApplicationUpdater />
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</ModalProvider>
				</Web3ContextProvider>
			</Provider>
		</HelmetProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
