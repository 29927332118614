import { get } from 'lodash';
import { useSelector } from 'react-redux';

export function useUsdcBalance(): any {
    return useSelector((state: any) => get(state, 'wallet.usdcBalance', 0));
}
export function useEklBalance(): any {
    return useSelector((state: any) => get(state, 'wallet.eklBalance', 0));
}
export function useAvaxBalance(): any {
    return useSelector((state: any) => get(state, 'wallet.avaxBalance', 0));
}
