import { MouseEvent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './item.scss';

export interface ISidebarItem {
	id: string;
	icon: string;
	text?: string;
	link?: string;
}
export type SidebarItemProps = {
	data: ISidebarItem;
	isSidebarItemActive: (data: ISidebarItem) => boolean;
	onClick?: (event: MouseEvent) => void;
};
const SidebarItem = ({
	data,
	isSidebarItemActive,
	onClick
}: SidebarItemProps) => {
	return (
		<li className="mm-sidebar-item">
			<Link
				type="button"
				className={isSidebarItemActive(data) ? 'active' : ''}
				to={data.link ?? '#'}
				onClick={onClick}
			>
				<OverlayTrigger
					overlay={
						<Tooltip id={data.id} placement="right">
							{data.text}
						</Tooltip>
					}
				>
					<img src={data.icon} alt={data.text} />
				</OverlayTrigger>
				{data.text ? <span>{data.text}</span> : <></>}
			</Link>
		</li>
	);
};
export default SidebarItem;
