import './index.scss';
import FooterLink from './link';

const Footer = () => {
	return (
		<div className="mm-footer">
			<ul>
				<li>
					<FooterLink
						data={{
							icon: '/assets/images/icons/icon-sniper-new.svg',
							link: '',
							text: 'Sniper New',
							count: '99+',
							countStyle: { backgroundColor: '#F04866' }
						}}
					/>
				</li>
				<li>
					<FooterLink
						data={{
							icon: '/assets/images/icons/icon-following.svg',
							link: '',
							text: 'Following'
						}}
					/>
				</li>
				<li>
					<FooterLink
						data={{
							icon: '/assets/images/icons/gift.webp',
							link: '',
							text: 'Refer',
							count: '0'
						}}
					/>
				</li>
			</ul>

			<ul>
				<li>
					<FooterLink
						small
						data={{
							icon: '/assets/images/icons/icon-academy.svg',
							link: '',
							text: 'Academy'
						}}
					/>
				</li>
				<li>
					<FooterLink
						small
						data={{
							icon: '/assets/images/icons/icon-about.svg',
							link: '',
							text: 'About'
						}}
					/>
				</li>
				<li>
					<FooterLink
						small
						data={{
							icon: '/assets/images/icons/icon-bot.svg',
							link: '',
							text: 'Bot'
						}}
					/>
				</li>
				<li>
					<FooterLink
						small
						data={{
							icon: '/assets/images/icons/icon-api.svg',
							link: '',
							text: 'API'
						}}
					/>
				</li>
				<li>
					<FooterLink
						small
						data={{
							icon: '/assets/images/icons/icon-x-circle.svg',
							link: ''
						}}
					/>
				</li>
				<li>
					<FooterLink
						small
						data={{
							icon: '/assets/images/icons/icon-telegram-circle.svg',
							link: ''
						}}
					/>
				</li>
			</ul>
		</div>
	);
};
export default Footer;
