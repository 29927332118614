import { Button, Modal } from 'react-bootstrap';
import './index.scss';

export type ConnectPopupProps = {
	show: boolean;
	onClose: () => void;
	onConnectTelegram: () => void;
	onSignInWithWallet: () => void;
};
const ConnectPopup = ({
	show,
	onClose,
	onConnectTelegram,
	onSignInWithWallet
}: ConnectPopupProps) => {
	return (
		<Modal
			show={show}
			fullscreen
			backdrop="static"
			keyboard={false}
			onHide={onClose}
			className="mm-connect-popup"
		>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<h1>Discover Faster, Trading In Seconds 🚀</h1>
				<p>
					Fast on-chain, click to trade; Auto sell with stop loss/take profit.
				</p>
				<div className="action">
					<Button variant="info" className='px-4' onClick={onConnectTelegram}>
						<img src="/assets/images/icons/icon-telegram.svg" alt="telegram" />
						<span>Connect Telegram</span>
					</Button>
					<Button variant="text" onClick={onSignInWithWallet}>
						<span>Or Sign In With Wallet</span>
						<img
							src="/assets/images/icons/icon-arrow-right.svg"
							alt="telegram"
						/>
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};
export default ConnectPopup;
