import './index.scss';

const NewCreationsCard = () => {
	return (
		<div className="mm-new-creation-card">
			<div className="image">
				<img src="/assets/images/avatar.png" alt="" />
			</div>
			<div className="info">
				<div className="top">
					<b>MAGIC</b>
					<span>SPACEX DRAGON NAME</span>
					<button type="button" className="copy">
						<img src="/assets/images/icons/icon-copy-fill.svg" alt="" />
					</button>
					<button type="button" className="marked">
						<img src="/assets/images/icons/icon-star.svg" alt="" />
					</button>
					<button type="button" className="buy">
						<img src="/assets/images/icons/icon-buy.svg" alt="" />
						<span>Buy</span>
					</button>
				</div>
			</div>
		</div>
	);
};
export default NewCreationsCard;
