import React, { useState } from 'react';

import { useWeb3React } from '@web3-react/core';
import { ConnectionOptions } from '../evm-web3/ConnectionOptions';
import useModal from './useModal';
import { ConnectionType } from '../../libs/connections';

interface ReturnType {
    onPresentConnectModal: () => void;
}

const useWalletModal = (): ReturnType => {
    const { chainId, account, isActive } = useWeb3React();
    const [connectionType, setConnectionType] = useState<ConnectionType | null>(null);

    const [onPresentConnectModal] = useModal(
        <ConnectionOptions
            activeConnectionType={connectionType}
            isConnectionActive={isActive}
            onActivate={setConnectionType}
            onDeactivate={setConnectionType}
        />,
    );
    return { onPresentConnectModal };
};

export default useWalletModal;
