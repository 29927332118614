import { MouseEvent, useEffect, useState } from 'react';
import {
	Navigate,
	Route,
	Routes,
	useNavigate,
	useSearchParams
} from 'react-router-dom';
import './App.scss';
import ConnectPopup from './components/connect-popup';
import Footer from './components/footer';
import Header, { IHeaderMenuItem, IHeaderUserInfo } from './components/header';
import Sidebar from './components/sidebar';
import { ISidebarItem } from './components/sidebar/item';
import StartNewCoinPopup from './components/start-new-coin-popup';
import { APP_CONFIGS, CHAINS, PAGE_URLS } from './constants';
import AppLayout from './layouts/app.layout';
import { ChainType } from './models';
import DiscoverPage from './pages/discover';
import FollowPage from './pages/follow';
import HoldingPage from './pages/holding';
import MemePage from './pages/meme';
import MyWalletPage from './pages/my-wallet';
import NewPairPage from './pages/new-pair';
import ReferralPage from './pages/referral';
import SunPumpPage from './pages/sun-pump';
import TokenPage from './pages/token';
import TradePage from './pages/trade';
import TrendingPage from './pages/trending';
import useWalletModal from './components/wallet-connect-modal';

const USER_INFO = {
	avatar: '/assets/images/avatar.png',
	walletAddress: '9PqCM...aG9',
	balance: 0,
	balanceSymbol: '/assets/images/icons/icon-sol.svg'
};
function App() {
	const navigate = useNavigate();
	const { onPresentConnectModal } = useWalletModal();
	const [searchParams] = useSearchParams();
	const [currentChain, setCurrentChain] = useState<ChainType>(
		APP_CONFIGS.CHAIN_DEFAULT as ChainType
	);
	const [menus, setMenus] = useState<IHeaderMenuItem[]>(CHAINS[0].menus);
	const [showCreateCoin, setShowCreateCoin] = useState(false);
	const [showConnect, setShowConnect] = useState(false);
	const [userInfo, setUserInfo] = useState<IHeaderUserInfo | undefined>();

	useEffect(() => {
		const chainFromUrl = searchParams.get(APP_CONFIGS.CHAIN_QUERY_PARAM);
		if (chainFromUrl) {
			setCurrentChain(chainFromUrl.toLowerCase() as ChainType);
			const chainConfig = CHAINS.find(
				chain => chain.id === chainFromUrl.toLowerCase()
			);
			setMenus(
				chainConfig?.menus?.map(menu => ({
					...menu,
					link: `${menu.link}?${APP_CONFIGS.CHAIN_QUERY_PARAM}=${chainConfig.id}`
				})) ?? []
			);
		}
	}, [searchParams]);

	useEffect(() => {
		if (window.location.pathname === '/') {
			navigate(PAGE_URLS.HOMEPAGE, { replace: true });
		}
	}, [navigate]);

	const onSidebarItemClick = (event: MouseEvent, item: ISidebarItem) => {
		if (item.id === 'setting') {
			event.preventDefault();
			alert(1);
		}
	};

	const onMenuItemClick = (menu: IHeaderMenuItem) => {
		console.log(menu);
	};

	const onConnect = () => {
		// setShowConnect(true);
		onPresentConnectModal();
	};

	const onCloseConnectPopup = () => {
		setShowConnect(false);
	};

	const onConnectTelegram = () => {
		setUserInfo(USER_INFO);
		setShowConnect(false);
	};

	const onSignInWithWallet = () => {
		setUserInfo(USER_INFO);
		setShowConnect(false);
	};

	const onStartNewCoin = () => {
		setShowCreateCoin(true);
	};

	const onStartNewCoinSubmit = () => {
		setShowCreateCoin(false);
	};

	const onStartNewCoinClose = () => {
		setShowCreateCoin(false);
	};

	const onCopyWalletAddress = () => {};

	const onSwitchLogin = () => {};

	const onDisconnect = () => {};

	return (
		<Routes>
			<Route
				element={
					<AppLayout
						header={
							<Header
								menus={menus}
								userInfo={userInfo}
								onMenuItemClick={onMenuItemClick}
								onStartNewCoin={onStartNewCoin}
								onConnect={onConnect}
								onCopyWalletAddress={onCopyWalletAddress}
								onSwitchLogin={onSwitchLogin}
								onDisconnect={onDisconnect}
							/>
						}
						sidebar={
							<Sidebar
								menus={[
									...CHAINS,
									{
										id: 'setting',
										icon: '/assets/images/icons/icon-setting.svg',
										text: 'Setting'
									}
								]}
								homepageUrl={PAGE_URLS.HOMEPAGE}
								logo={APP_CONFIGS.LOGO}
								isSidebarItemActive={item => currentChain === item.id}
								onSidebarItemClick={onSidebarItemClick}
							/>
						}
						footer={<Footer />}
						modal={
							<>
								<ConnectPopup
									show={showConnect}
									onClose={onCloseConnectPopup}
									onConnectTelegram={onConnectTelegram}
									onSignInWithWallet={onSignInWithWallet}
								/>
								<StartNewCoinPopup
									show={showCreateCoin}
									onCreate={onStartNewCoinSubmit}
									onClose={onStartNewCoinClose}
								/>
							</>
						}
					/>
				}
			>
				<Route index element={<TrendingPage />} />
				<Route path="/meme" element={<MemePage />} />
				<Route path="/sun-pump" element={<SunPumpPage />} />
				<Route path="/new-pair" element={<NewPairPage />} />
				<Route path="/trade" element={<TradePage />} />
				<Route path="/holding" element={<HoldingPage />} />
				<Route path="/discover" element={<DiscoverPage />} />
				<Route path="/follow" element={<FollowPage />} />
				<Route path="/my-wallet" element={<MyWalletPage />} />
				<Route path="/referral" element={<ReferralPage />} />
				<Route path="/token" element={<TokenPage />} />
				<Route
					path="*"
					element={<Navigate to="/?chain=sol" replace={true} />}
				/>
			</Route>
		</Routes>
	);
}

export default App;
