import { CSSProperties, ReactNode } from 'react';
import './SectionTitle.scss';

export interface SectionTitleProps {
	size?: 'sm';
	centered?: boolean;
	hideDivider?: boolean;
	dividerInline?: boolean;
	children?: ReactNode;
	mb?: string;
	className?: string;
	style?: CSSProperties;
}
const SectionTitle = ({
	size,
	centered,
	hideDivider,
	dividerInline,
	children,
	mb,
	className,
	style
}: SectionTitleProps) => {
	return (
		<div
			className={`section-title ${size ?? ''} ${className ?? ''} ${
				centered ? 'center' : ''
			} ${dividerInline ? 'divider-inline' : ''}`}
			style={{
				...style,
				marginBottom: mb
			}}
		>
			<h3>
				{centered && !hideDivider ? (
					<img
						className="divider"
						src={'./images/left-divider.png'}
						alt="divider"
					/>
				) : (
					<></>
				)}
				<span>{children}</span>
				{(centered || dividerInline) && !hideDivider ? (
					<img
						className="divider"
						src={'./images/right-divider.png'}
						alt="divider"
					/>
				) : (
					<></>
				)}
			</h3>
			{!dividerInline && !hideDivider ? (
				<img
					className="divider"
					src={'./images/right-divider.png'}
					alt="divider"
				/>
			) : (
				<></>
			)}
		</div>
	);
};
export default SectionTitle;
