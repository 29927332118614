import { useState } from 'react';
import {
	Accordion,
	Button,
	Col,
	Form,
	InputGroup,
	Modal,
	Row
} from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';

export type StartNewCoinPopupProps = {
	show: boolean;
	onCreate: () => void;
	onClose: () => void;
};
const StartNewCoinPopup = ({
	show,
	onCreate,
	onClose
}: StartNewCoinPopupProps) => {
	const fileTypes = ['jpg', 'png', 'webp', 'gif', 'mp4', 'webm'];
	const [file, setFile] = useState<File | null>(null);
	const handleFileChange = (file: File) => {
		setFile(file);
	};
	const handleClose = () => {
		setFile(null);
		onClose();
	};
	return (
		<Modal
			scrollable
			centered
			show={show}
			backdrop="static"
			keyboard={false}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Start a new coin</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col sm={8} xs={7}>
						<Form.Group className="mb-3" controlId="txtName">
							<Form.Label>Name</Form.Label>
							<Form.Control
								name="name"
								type="text"
								placeholder=""
								autoFocus
								required
							/>
						</Form.Group>
					</Col>
					<Col sm={4} xs={5}>
						<Form.Group className="mb-3" controlId="txtTicker">
							<Form.Label>Ticker</Form.Label>
							<Form.Control
								name="ticker"
								type="text"
								placeholder=""
								maxLength={10}
								required
							/>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group className="mb-3" controlId="txtDescription">
					<Form.Label>Description</Form.Label>
					<Form.Control name="description" as="textarea" rows={2} required />
				</Form.Group>
				<Form.Group className="mb-3" controlId="txtImageOfVideo">
					<Form.Label>Image or Video</Form.Label>
					<div className="mm-uploader">
						<FileUploader
							name="imageOrVideo"
							required
							handleChange={handleFileChange}
							types={fileTypes}
						/>
					</div>
					{file && (
						<div className="text-center py-3">
							{file.type.startsWith('image') ? (
								<img
									src={URL.createObjectURL(file)}
									alt="preview"
									height={100}
								/>
							) : file.type.startsWith('video') ? (
								<video height={100} className="w-100" controls>
									<source src={URL.createObjectURL(file)} type={file.type} />
									Your browser does not support the video tag.
								</video>
							) : (
								<p>File type not supported for preview</p>
							)}
						</div>
					)}
				</Form.Group>
				<Form.Group>
					<Accordion defaultActiveKey="-1">
						<Accordion.Item eventKey="0">
							<Accordion.Header>More options</Accordion.Header>
							<Accordion.Body>
								<InputGroup className="mb-2">
									<InputGroup.Text id="lbl">Twitter</InputGroup.Text>
									<Form.Control
										name="twitter"
										type="text"
										placeholder="optional"
									/>
								</InputGroup>
								<InputGroup className="mb-2">
									<InputGroup.Text>Telegram</InputGroup.Text>
									<Form.Control
										name="telegram"
										type="text"
										placeholder="optional"
									/>
								</InputGroup>
								<InputGroup>
									<InputGroup.Text>Website</InputGroup.Text>
									<Form.Control
										name="website"
										type="url"
										placeholder="optional"
									/>
								</InputGroup>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Form.Text className="opacity-25">
					Tip: Coin data cannot be changed after creation
				</Form.Text>
				<Button
					className="w-100"
					variant="warning"
					size="lg"
					onClick={onCreate}
				>
					Create coin
				</Button>
				<Form.Text>
					When your coin completes its bonding curve you receive 0.5 SOL
				</Form.Text>
			</Modal.Footer>
		</Modal>
	);
};
export default StartNewCoinPopup;
