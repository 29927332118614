import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";

export const useOnBlockUpdated = (callback: (blockNumber: number) => void) => {
    const { provider } = useWeb3React();
    useEffect(() => {
        if (!provider) {
            return;
        }
        const subscription = provider.on("block", callback);
        return () => {
            subscription.removeAllListeners();
        };
    });
};
