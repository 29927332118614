import {
	Button as BsButton,
	ButtonProps as BsButtonProps
} from 'react-bootstrap';
import './Button.scss';
import buttonEffect from '../../assets/images/btn-effect.png';

export type ButtonVariant = 'primary' | 'success' | 'danger' | 'warning';
export interface ButtonProps extends BsButtonProps {
	size?: 'sm';
	variant?: ButtonVariant;
	btnInnerClassName?: string;
}
const Button = ({
	children,
	className,
	btnInnerClassName,
	...props
}: ButtonProps) => {
	return (
		<BsButton className={`button ${className ?? ''}`} {...props}>
			<span className={btnInnerClassName ?? ''}>{children}</span>
			<img src={buttonEffect} className="effect" alt="" />
		</BsButton>
	);
};
export default Button;
