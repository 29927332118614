import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import './app.layout.scss';

export type AppLayoutProps = {
	header: ReactNode;
	sidebar: ReactNode;
	footer: ReactNode;
	modal: ReactNode;
};
const AppLayout = ({ header, sidebar, footer, modal }: AppLayoutProps) => {
	return (
		<div className="mm-app">
			{header}
			{sidebar}
			<main className={header && sidebar ? 'mm-main' : 'mm-main-blank'}>
				<Outlet />
			</main>
			{footer}
			{modal}
		</div>
	);
};
export default AppLayout;
