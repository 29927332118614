//@ts-nocheck
import React from 'react';
import { Modal } from 'react-bootstrap';

import { Option } from './Option';
import {
	ConnectionType,
	getHasMetaMaskExtensionInstalled
} from '../../libs/connections';
import { METAMASK_URL } from '../../libs/constants';
import SectionTitle from '../../section-title/SectionTitle';

type ConnectOptionsParams = {
	activeConnectionType: ConnectionType | null;
	isConnectionActive: boolean;
	onActivate: (connectionType: ConnectionType) => void;
	onDeactivate: (connectionType: null) => void;
	onDismiss?: () => void;
};

export const ConnectionOptions = ({
	activeConnectionType,
	isConnectionActive,
	onActivate,
	onDeactivate,
	onDismiss = () => null
}: ConnectOptionsParams) => {
	const onHide = () => {
		onDismiss();
	};

	const getOptions = (isActive: boolean) => {
		const hasMetaMaskExtension = getHasMetaMaskExtensionInstalled();

		const isNoOptionActive =
			!isActive || (isActive && activeConnectionType === null);

		const metaMaskOption = hasMetaMaskExtension ? (
			<Option
				isEnabled={
					isNoOptionActive || activeConnectionType === ConnectionType.INJECTED
				}
				isConnected={activeConnectionType === ConnectionType.INJECTED}
				connectionType={ConnectionType.INJECTED}
				onActivate={onActivate}
				onDeactivate={onDeactivate}
				onDismiss={onHide}
				buttonStyle="warning"
			/>
		) : (
			<a
				className="button w-100 btn btn-warning"
				href={METAMASK_URL}
				target="_blank"
			>
				<span>Install Metamask</span>
			</a>
		);

		const coinbaseWalletOption = (
			<Option
				isEnabled={
					isNoOptionActive ||
					activeConnectionType === ConnectionType.COINBASE_WALLET
				}
				isConnected={activeConnectionType === ConnectionType.COINBASE_WALLET}
				connectionType={ConnectionType.COINBASE_WALLET}
				onActivate={onActivate}
				onDeactivate={onDeactivate}
				onDismiss={onHide}
			/>
		);

		const walletConnectOption = (
			<Option
				isEnabled={
					isNoOptionActive ||
					activeConnectionType === ConnectionType.WALLET_CONNECT
				}
				isConnected={activeConnectionType === ConnectionType.WALLET_CONNECT}
				connectionType={ConnectionType.WALLET_CONNECT}
				onActivate={onActivate}
				onDeactivate={onDeactivate}
				onDismiss={onHide}
			/>
		);

		return (
			<>
				{metaMaskOption}
				{coinbaseWalletOption}
				{walletConnectOption}
			</>
		);
	};

	return (
		<React.Fragment>
			<Modal
				show={true}
				id="connectWalletModal"
				size="sm"
				centered={true}
				onHide={onHide}
			>
				<Modal.Header className="px-4 pt-4" closeButton>
					<SectionTitle hideDivider size="sm">
						Connect Wallet
					</SectionTitle>
				</Modal.Header>

				<Modal.Body className="p-4 pb-5">
					<div
						className="px-sm-2"
						style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
					>
						{getOptions(isConnectionActive)}
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};
